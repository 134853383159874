.carousel-dots {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.dot {
  width: 12px;
  height: 12px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: #bbb;
  transition: background-color 0.3s;
}

.dot.active {
  background-color: #717171;
}

.dot:hover {
  background-color: #555;
}

.carousel-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  height: 600px;
  width: 60%;
  box-shadow: 20px 30px 55px #555;
  border-radius: 13px;
  margin: 0 auto;
}



.carousel-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px; 
}

.carousel-arrow-left,
.carousel-arrow-right {
  background: none;
  border: none;
  font-size: 14px; 
  cursor: pointer;
  color: #fff;
  padding: 5px;
  transition: transform 0.3s ease;
  
 
}

.carousel-arrow .carousel-arrow-right{
  font-style: inherit;
}

.carousel-arrow-left:hover,
.carousel-arrow-right:hover {
  transform: scale(1.2);
}

.carousel-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px; /* Ne legyen távolság a pontok között */
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #bbb;
  /* cursor: pointer; */
}

.dot.active {
  background-color: #717171;
}


@media screen and (min-width: 200px) and (max-width: 768px) {

  .carousel-container, .iframe-container {
    margin: 0 auto; /* Középre igazítás */
   
  }

  .carousel-container {
    border-radius: 0px;
    margin: 0 auto;
  }

  .dot {
    width: 10px;
    height: 10px;
    background-color: rgb(173, 173, 173);
    transition: background-color 0.3s;
    margin-top: 5px;
  }
  
  .carousel-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    gap: 0px; 
  }

  .carousel-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px; 
  }

  .iframe-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; /* Teljes szélességet vegye fel */
    height: auto; /* Automatikus magasság */
   
  }

  .iframe-container iframe {
    width: 100%; /* Az iframe teljes szélessége */
    height: 400px; /* Magasság igazítása a szülőhöz */
    border: none; /* Távolítsd el a szegélyt */
  }



}

 

/* Bal oldali nyíl */
.carousel-arrow-left {
  left: 15%;
}

/* Jobb oldali nyíl */
.carousel-arrow-right {
  right: 15%;
}






.carousel {
  position: relative;
  height: 400px;
  width: 100%;
  overflow: hidden;
  z-index: 10; /* A carousel elemei a gradient alatt lesznek */
}


.carousel-section{
  /* background: linear-gradient(to bottom, #a1a1a1, #ffffff, #ffffff ); */
  width: 100%;
}



.carousel-title{
  color: black;
  margin-bottom: 50px;
  font-size: 36px;
  text-align: center;
}

.carousel div {
  position: absolute;
  transition: transform 400ms, left 400ms, opacity 400ms, z-index 0s;
  opacity: 1;
}

.carousel div img {
  width: 100%;
  transition: width 400ms;
  -webkit-user-drag: none;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  border-radius: 10px;
}

.carousel div.hideLeft {
  left: -100%;
  opacity: 0;
}

.carousel div.hideRight {
  left: 100%;
  opacity: 0;
}

.carousel div.prev {
  z-index: 5;
  left: 30%;
  transform: translateY(50px) translateX(-50%);
}

.carousel div.prev img {
  width: 300px;
}

.carousel div.prevLeftSecond {
  z-index: 4;
  left: 15%;
  transform: translateY(50%) translateX(-50%);
  opacity: 0.7;
}

.carousel div.selected {
  z-index: 10;
  left: 50%;
  transform: translateY(0px) translateX(-50%);
}

.carousel div.next {
  z-index: 5;
  left: 70%;
  transform: translateY(50px) translateX(-50%);
}

.carousel div.next img {
  width: 300px;
}

.carousel div.nextRightSecond {
  z-index: 4;
  left: 85%;
  transform: translateY(50%) translateX(-50%);
  opacity: 0.7;
}

.carousel-container {
  position: relative; /* Ez biztosítja, hogy a .carousel-controls a containerhez igazodjon */
}

/* Gombok stílusa */


.carousel-controls button {
  background: transparent;
  border: none;
  font-size: 40px;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  padding: 10px;
  /* transition: color 0.3s; */
  pointer-events: auto;
}

.carousel-controls button:hover {
  color: rgba(0, 0, 0, 0.5);
}

.carousel-controls button:focus {
  outline: none;
}


@media (max-width: 768px) {

  .carousel-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    height: 500px;
    width: 100%; 
    box-shadow: none;
    /* border-radius: 13px; */
    margin: 0 auto;
}


.carousel-container::before {
  content: '';
  position: absolute;
  top: 80%; /* A magasság felső 25%-ánál kezdődik */
  left: 0;
  width: 100%;
  height: 20%; /* A magasság középső 50%-át fedi le */
  background: none;
  pointer-events: none; /* Az interakciót nem blokkolja */
  z-index: 20; /* A gradient az elemek felett lesz */
}


  .carousel {
    height: 400px; /* Mobilon kisebb magasság */
    width: 380px; /* A carousel szélessége teljes képernyő */
    overflow: hidden; /* Nem látható elemek elrejtése */
    margin-left: 0; /* Nincs eltolás */
  }

  .carousel div {
    left: 0; /* Alapértelmezett helyzet */
    opacity: 0; /* Alapértelmezés szerint láthatatlan */
    z-index: 1; /* Alacsony z-index */
    transform: none; /* Ne legyen eltolás */
    transition: opacity 400ms ease, z-index 0s;
  }

  .carousel div.selected {
    opacity: 1; /* Csak a kiválasztott kép legyen látható */
    z-index: 10; /* Magasabb z-index */
  }

  .carousel div.prev,
  .carousel div.next,
  .carousel div.hideLeft,
  .carousel div.hideRight {
    opacity: 0; /* Ezek az elemek nem láthatóak */
    z-index: 0; /* Nincs interakció */
  }

  .carousel div img {
    width: 100%; /* A kép szélessége a carousel szélességét követi */
    height: auto; /* Magasság automatikusan */
    object-fit: cover; /* A képek kitöltik a rendelkezésre álló helyet */
  }


  
    
      .carousel-controls .cbutton {
        font-size: 40px;
        outline: none; /* Távolítsa el az alapértelmezett fókusz szegélyt */

      }
    
      .carousel-controls .cbutton:first-child {
        position: relative;
        left: -10px; /* Bal oldal */
        outline: none; /* Kék fókuszjelzés eltávolítása */
      }
    
      .carousel-controls .cbutton:last-child {
        position: relative;
        right: -10px;
        outline: none; 
      }

      
  .iframe-container {
    pointer-events: none; 
    overflow: hidden;
  }
  
  .iframe-container.active {
    pointer-events: auto; 
    overflow: auto;
  }
}
