#section-wave {
    position: relative;
    width: 100%;
    height: 25vh;
    background-color: rgb(8, 8, 8);;
    overflow: hidden;
    letter-spacing: 2px;
    margin-top: 150px;
}

.text-center {
    display: flex;
    flex-direction: column; /* Ha több elem van, ez függőleges elrendezést ad */
    justify-content: center; /* Horizontálisan középre helyez */
    align-items: center; /* Vertikálisan középre helyez */
    height: 100%; /* A teljes szekciót lefedi */
    margin-top: 0 !important; /* Távolítsd el a korábbi margin-t */
}


.wavep2{
    color: rgb(255, 255, 255);
      letter-spacing: 1px;
      line-height: 1em;
      font-size: 18px;
      margin-bottom: 30px;
      height: 40%;
      margin-top: -20px;
      align-items: center;
      justify-content: center;
    }


#section-wave .wave {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background: url('./wave.png');
    background-size: 1000px 100px;

}

#section-wave .wave.wave1 
{
animation: animate 30s linear infinite;
z-index: 1000;
opacity: 1;
animation-delay: 0s;
bottom: 0;
}
@keyframes animate {
    0% {
        background-position-x: 0;
    }
    100% {
        background-position-x: 1000px;
    }
}

#section-wave .wave.wave2
{
animation: animate2 15s linear infinite;
z-index: 999;
opacity: 0.5;
animation-delay: -5s;
bottom: 10px;

}
@keyframes animate2 {
    0% {
        background-position-x: 0;
    }
    100% {
        background-position-x: 1000px;
    }
}

#section-wave .wave.wave3
{
animation: animate 15s linear infinite;
z-index: 998;
opacity: 0.2;
animation-delay: -2s;
bottom: 15;
}
@keyframes animate2 {
    0% {
        background-position-x: 0;
    }
    100% {
        background-position-x: 1000px;
    }
}


#section-wave .wave.wave4
{
animation: animate2 15s linear infinite;
z-index: 997;
opacity: 0.7;
animation-delay: -5s;
bottom: 20px;
}
@keyframes animate2 {
    0% {
        background-position-x: 0;
    }
    100% {
        background-position-x: 1000px;
    }
}

.wave-p{
  color: rgb(255, 255, 255);
  letter-spacing: 2px;
  line-height: 1.7em;
  font-size: 16px;
  margin-bottom: 45px;
  height: 40%;
}

@media screen and (min-width: 350px) and (max-width: 400px) {
    #section-wave {
        position: relative;
        width: 100%;
        height: 26vh;
        background-color: rgb(0, 0, 0);;
        overflow: hidden;
        letter-spacing: 2px;
        margin-top: 100px;
    }

    #section-wave .wave {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        /* height: 65px; */
        background: url('./wave.png');
        background-size: 1000px 100px;
    
    }

    .wave-p{
    color: rgb(255, 255, 255);
      letter-spacing: 2px;
      line-height: 1.7em;
      font-size: 12px;
      margin-bottom: 30px;
      height: 40%;
      margin-top: -55px;
    }

    .wavep2{
    color: rgb(255, 255, 255);
      letter-spacing: 2px;
      line-height: 1.7em;
      font-size: 12px;
      margin-bottom: 30px;
      height: 40%;
      margin-top: -20px;
      align-items: center;
      justify-content: center;
    }

    /* .text-center{
        margin-top: 90px !important;
    } */

    .text-center {
        display: flex;
        flex-direction: column; /* Ha több elem van, ez függőleges elrendezést ad */
        justify-content: center; /* Horizontálisan középre helyez */
        align-items: center; /* Vertikálisan középre helyez */
        height: 100%; /* A teljes szekciót lefedi */
        margin-top: 0 !important; /* Távolítsd el a korábbi margin-t */
    }
    
}


@media screen and (min-width: 298px) and (max-width: 375px) {
    #section-wave {
        position: relative;
        width: 100%;
        height: 30vh;
        background-color: rgb(0, 0, 0);;
        overflow: hidden;
        letter-spacing: 2px;
        margin-top: 90px;
    }

    #section-wave .wave {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        /* height: 65px; */
        background: url('./wave.png');
        background-size: 1000px 100px;
    
    }

    .wave-p{
    color: rgb(255, 255, 255);
      letter-spacing: 2px;
      line-height: 1.7em;
      font-size: 12px;
      margin-bottom: 30px;
      height: 40%;
      margin-top: -55px;
    }

    .wavep2{
    color: rgb(255, 255, 255);
      letter-spacing: 2px;
      line-height: 1.7em;
      font-size: 12px;
      margin-bottom: 30px;
      height: 40%;
      margin-top: -20px;
    }

    /* .text-center{
        margin-top: 70px !important;
    } */
    

    .text-center {
        display: flex;
        flex-direction: column; /* Ha több elem van, ez függőleges elrendezést ad */
        justify-content: center; /* Horizontálisan középre helyez */
        align-items: center; /* Vertikálisan középre helyez */
        height: 100%; /* A teljes szekciót lefedi */
        margin-top: 0 !important; /* Távolítsd el a korábbi margin-t */
    }
}