/* Fő konténer */
.contact-form-container {
  display: flex;
  width: 60%;
  height: 500px;
  font-family: Arial, sans-serif;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
  margin-top: 15%;
  margin-bottom: 5%;
  border-radius: 13px;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

/* Bal oldali form szekció */
.contact-form-left {
  flex: 1;
  background-color: #fff;
  display: flex;
  flex-direction: column; /* Az elemek egymás alá kerülnek */
  align-items: center; /* Vízszintes középre igazítás */
  justify-content: flex-start; /* A tartalom a tetejére kerül */
  padding: 30px;
  margin: 0 auto;
  border-radius: 0px 0px 16px 16px;
  /* margin-left: 30px; */ 
  margin-bottom: 30px;
}

/* A h1 és az input mezők közötti távolságot csökkentjük */
.h1-c {
  font-size: 24px;
  text-align: center;
  color: #000000;
  margin-bottom: 20px; /* Távolság a felirat és az input mezők között */
  margin-top: 20px;
}

.contact-form {
  width: 80%; /* A szélesség 100%-ra állítása */
  height: 80%;
  padding: 12px 8px;
  margin-bottom: 20px; /* Távolság az input mezők között */
  border-radius: 8px; /* Lekerekített sarkok */
  outline: none;
  font-size: 14px;
  /* margin-top: 10px; */
  
}

.adat-pd {
  display: flex;
  align-items: center; /* A checkbox és a szöveg középre igazítása */
  margin-top: 10px;
}

.adat-box {
  display: flex; /* Flexbox, hogy a checkbox és a szöveg egy sorban legyen */
  align-items: center; /* A checkbox és a szöveg középre igazítása */
  padding-bottom: 10px;
  margin-bottom: 10px;
  width: 100%;
}

input[type="checkbox"] {
  margin-right: 8px; /* Távolság a checkbox és a szöveg között */
}

a {
  text-decoration: none; /* Eltávolítjuk az aláhúzást */
  color: blue; /* A link színe */
  margin-left: 5px; /* A szöveg eltolása */
}

.box{
  width: 50%;
  padding: 13px 8px;
 
}



.contact-form textarea{
  width: 90%;
  padding-top: 10px;
  padding-left: 10px;
  border: none; /* Nincs border */
  outline: none; /* Nincs fókusz körvonal */
  background-color: transparent; /* Átlátszó háttér */
  margin-top: 15px;
  resize: none; 
  background-color: rgba(247, 242, 242, 0.774);
  box-shadow: rgb(202, 200, 200);
  border-radius: 8px;
  height: 30%;
}

/* Eltávolítjuk a border-t és a fókusz körvonalat */
.contact-form input
 {
  width: 90%;
  padding: 15px 8px;
  border: none; /* Nincs border */
  outline: none; /* Nincs fókusz körvonal */
  background-color: rgba(247, 242, 242, 0.774); /* Átlátszó háttér */
  box-shadow: rgb(202, 200, 200);
  border-radius: 8px;
  margin-top: 8px;
  resize: none; 
  margin: 6px;

}

/* Fókuszáláskor ne változzon a border szín */
.contact-form input:focus,
.contact-form textarea:focus {
  border-color: none; /* Fókuszáláskor ne legyen szegély */
  resize: none; 
}

.contact-form label {
  margin-bottom: 5px; /* Távolság a label és az input között */
}

.contact-form button {
  width: 40%;
  padding: 10px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
}

.contact-form button:hover {
  background-color: #000000;
}

/* Jobb oldali szöveges szekció */
.contact-form-right {
  flex: 1;
  background-color: #000;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 50px;
  box-sizing: border-box;
  border-radius: 0px 16px 16px 0px;
}

.et_contact_nyilatkozat{
  padding-right: 45%
}


@media (max-width: 768px) {
  .contact-form-container {
    flex-direction: column; /* Egymás alá rendezi az elemeket mobilon */
    width: 90%; /* A szélesség 90%-ra csökkentve kisebb képernyőkhöz */
    margin-top: 30%;
    margin-bottom: 10%;
    height: 700px;
  }

  .contact-form-left,
  .contact-form-right {
    /* width: 100%;  */
    flex: none; 
    padding: 20px; /* Kevesebb padding a kisebb képernyőkhöz */
    border-radius: 0; /* Mobilon nincs lekerekített sarok */
  }

  
.contact-form {
  /* width: 100%;  */
  height: 80%;
  padding: 20px 20px;
  margin-bottom: 20px; 
  border-radius: 8px; 
  outline: none;
  font-size: 14px;
  
  
}

.contact-form input {
  width: 100%;
  padding: 15px 8px;
  margin-right: 40px;
  margin-left: -5px;
  border: none;
  outline: none;
  background-color: rgba(247, 242, 242, 0.774);
  box-shadow: rgb(202, 200, 200);
  border-radius: 8px;
  margin-top: 15px;
  resize: none;
}

.contact-form textarea{
  width: 100%;
  padding-top: 10px;
  padding-left: 10px;
  margin-left: -5px;
  border: none; /* Nincs border */
  outline: none; /* Nincs fókusz körvonal */
  background-color: transparent; /* Átlátszó háttér */
  margin-top: 15px;
  resize: none; 
  background-color: rgba(247, 242, 242, 0.774);
  box-shadow: rgb(202, 200, 200);
  border-radius: 8px;
  height: 30%;
}


  .contact-form-left {
    margin-bottom: 20px; /* Távolság a két szekció között mobilon */
  }

  .contact-form-right {
    padding: 30px; /* Kevesebb padding a jobb oldalon */
    border-top-left-radius: 90px 90px;
    border-top-right-radius: 90px 90px;
    padding-bottom: 100px;

  }

  button {
    width: 100%; /* Gomb teljes szélességű mobilon */
  }

  textarea {
    resize: none; /* Mobilon a textarea mérete fix */
  }
}