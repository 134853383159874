.logo-s{
  /* background: linear-gradient(to bottom, #ffffff, #ffffff, #a1a1a1); */
  width: 110%;
}
.main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 800px;
    /* padding-bottom: 35%; */
    /* width: 100vh; */
   
   
   
  }
  
  .img {
    width: 70%;
    height: 500px;
    object-fit: cover;
    /* margin: auto 0; */
    margin-right: 5px;
  }


  @media (max-width: 768px) {
    .main{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
    padding-bottom: 40px;
    overflow-y: hidden;
    }
    .img{
      height: 600px;
      margin-right: 0px;
    }
  }

